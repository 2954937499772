/* eslint-disable */
import React from 'react';
import { CustomSimpleTooltip } from 'UI/atoms/Tooltips/Index';
import './ChartContainer.scss';

const ChartContainer = (props: any) => {
	const { title, titleTooltip, children, subtitle, ranking, footer, between = false, padding = 4, traceability = false } = props;

	return (
		<div
			className={`chart-container justify-content-${!between ? 'between' : ""} ${
				ranking ? 'p-0' : `p-${padding}`
			}`}
		>
			<div
				id="dashboard-header"
				className={`${traceability ? 'px-4 pt-4' : ''} ${(ranking || traceability) ? 'pt-4 pr-4 pl-4' : ''}`}
			>
				<div id="title-dashboard" className="pb-4">
					<div className={`d-flex flex-row align-items-center font-sfpro-regular font-14px mb-1`}>
						{title}
						{
							titleTooltip && (
								<div className='tooltipCustom d-flex'>
									<span className='icon-icon-help-circle font-14px ml-1 cursor-pointer'></span>
									<CustomSimpleTooltip position={{top: '20px', left: '-150px'}} maxWidth='200px' copy={titleTooltip} />
								</div>
							)
						}
					</div>
					{subtitle && (
						<div className={`text-dark-60 font-${((padding === 4) || traceability) ? "14" : "16"}px mb-1 font-sfpro-regular`}>
							{subtitle}
						</div>
					)}
				</div>
			</div>
			{children}
			{footer && (
				<div
					className={`text-dark-60 font-12px pt-2 w-100 mb-5 ${
						ranking ? 'pb-3 pr-4 pl-4' : ''
					}`}
				>
					{footer}
				</div>
			)}
		</div>
	);
};

export default ChartContainer;

export const ChartContainerHome = (props: any) => {
	const { title, children, subtitle, ranking } = props;
	return (
		<div
			className={`d-flex flex-column justify-content-between overflow-hidden w-100 rounded-08 box-shadow text-dark-100 bg-white-100 ${
				ranking ? 'p-0' : 'p-4'
			}`}
		>
			<div
				id="dashboard-header"
				className={`mb-3 ${ranking ? 'pt-4 pr-4 pl-4' : ''}`}
			>
				<div id="title-dashboard">
					<div className="font-sfpro-regular font-14px mb-1">
						{title}
					</div>
					{subtitle && (
						<div className="text-dark-60 font-14px mb-1 font-sfpro-regular">
							{subtitle}
						</div>
					)}
				</div>
			</div>
			{children}
		</div>
	);
};
